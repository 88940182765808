.mountain{
    max-width: 100%;

    margin-top: 30rem;
    position: absolute;
}

.mountain img{
    max-width: 100%;
}
