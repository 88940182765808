.our-work{
    position: relative;
    width: 100%;
    justify-content: space-between;
    display: flex;
    overflow: hidden;
}

.our-left{
    flex-direction: column;
    width: 50%;
    margin: 0 17rem;
}

.our-heading{
    color: white;
    width: 70%;
}

.our-heading h1{
    font-size: 3rem;
    text-transform: uppercase;
    width: 70%;
}

.our-divider{
    border: 1px solid var(--themeColor);
    width: 9.5rem;
}

.our-content{
    margin-top: 8rem;
    color: var(--white);
    width: 100%;
}

.our-content h1{
    text-transform: capitalize;
    font-size: 2rem;
}

.our-content p{
    margin-top: 4rem;
    font-size: 1.2rem;
    line-height: 2.1rem;
}

.points{
    margin-top: 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.our-dots{
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid var(--themeColor);
    margin-top: 5px;
    box-shadow: 0px 0px 10px 2px rgb(147, 204, 247);
    
}

.our-text{
    font-weight: 400;
    font-size: 1.3rem;
}

.our-logo{
    margin-top: 5rem;
}

.our-logo img{
    width: 10rem;
}

.our-right{
    display: flex;
    position: relative;
    margin-right: 17rem;
}

.our-below-content{
    display: flex;
}

.our-right-dots{
    display: flex;
    max-width: 100%;
}

.bullet-content{
    width: 100%;
}

.bullet-content h4{
    font-size: 1.2rem;
    color: rgba(181, 181, 181, 0.586);
    line-height: 2.3rem;
    font-weight: 100;
    cursor: pointer;
    font-weight: 500;
}

.bullet-content h4:active{
    color: var(--white);
}

.bullet-dots{
    width: 1.3rem;
    height: 0.6rem;
    position: absolute;
    background-color: none;
    margin-left: -2.5rem;
    margin-top: 0.9rem;
    border-radius: 10px;
    border: 1px solid rgba(181, 181, 181, 0.586);
}

.bullet-dots:active{
    background-color: var(--themeColor);
    border: none;
    box-shadow: 0 0 10px 0 var(--themeColor);
}

.our-arrows{
    position: absolute;
    margin-left: -6rem;
    margin-top: 3rem;
}

.our-arrows img{
    flex-direction: column;
    display: flex;
    width: 1.5rem;
    height: 3rem;
    cursor: pointer;
}

.our-arrows>:nth-child(1){
    margin-bottom: 2rem;
}

.our-container{
    background-color: rgb(35, 4, 60);
    width: 50rem;
    height: 15rem;
    position: absolute;
    margin-top: 4rem;
    z-index: 1;
    margin-left: -5rem;
    overflow: hidden;
}

.our-display{
    margin-top: 7rem;
    position: absolute;
    margin-left: -15rem;
    z-index: 9999;
}

.our-display img{
    height: 30rem;
}

.our-content button{
    width: auto;
    padding: 1rem;
    margin-top: 5rem;
    font-size: 1rem;
    cursor: pointer;
}


@media screen and (max-width: 680px) {
    .our-work{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        margin-top: -8rem;
        height: 125vh;
        position: absolute;
    }

    .our-left{
        margin: 0 1rem;
    }

    .our-heading{
        width: 100%;
    }

    .our-heading h1{
        font-size: 0.8rem;
    }

    .our-divider{
        width: 2.5rem;
        margin-left: 5.7rem;
        border: 1px solid var(--themeColor);
    }
    
    .our-content{
        margin: 2rem 0;
    }

    .our-content h1{
        font-size: 0.8rem;
    }

    .our-content p{
        max-width: 100%;
        margin: 1rem 0;
        font-size: 0.7rem;
    }

    .our-dots{
        width: 10px;
        height: 10px;
    }

    .our-text span{
        font-size: 0.7rem;
    }

    .our-logo{
        margin: 2rem 0;
    }

    .our-logo img{
        width: 5rem;
    }

    .our-content button{
        font-size: 0.5rem;
        width: 7rem;
        margin: auto;
        height: 1.9rem;
    }

    .our-right{
        margin: 0rem 4rem;
    }

    .our-display{
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .our-display img{
        width: 13rem;
        height: 10rem;
        margin-left: 20rem;
    }

    .our-container{
        height: 9rem;
        width: 15rem;
        right: -7.5rem;
    }

    .bullet-content h4{
        font-size: .6rem;
    }

    .bullet-dots{
        width: .8rem;
        height: .4rem;
        margin-top: 1rem;
    }

    .our-container{
        position: absolute;
        margin-right: 15rem;
    }
}
