.card-wrapper{
    position: relative;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    height: 500px;
}

.card-wrapper:hover{
    outline: none;
    border: none;
    background-color: rgb(34, 22, 66);
    transform: translateY(-40px);
    transition: all .1s;

}


.card-wrapper:active{
    outline: none;
    border: none;
}

.card-wrapper:focus{
    outline: none;
    border: none;
}

.card{
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    position: absolute;
    text-align: center;
    justify-content: center;
    align-items: center;
}



.card-image img{
    margin: auto;
    margin-top: 4rem;
}

.card-divider{
    border: 1px solid var(--themeColor);
    width: 3rem;
    margin: auto;
    margin-top: 1rem;
}

.card-heading{
    color: var(--white);
    text-transform: capitalize;
    width: 70%;
    margin: auto;
    margin-top: 2rem;
}

.card-heading h1{
    font-size: 2rem;
}

.card-p{
    color: var(--white);
    margin-top: 2rem;
    display: none;
    animation-name: slide;
    animation-duration: 2s;
    opacity: 100%;
    padding: 0 1rem;
    margin-right: 1rem;
}

.read{
    color: var(--purple);
    display: none;
    animation-name: slide;
    animation-duration: 3s;
    opacity: 100%;
    margin-left: 0.1rem;
    margin-top: 2rem;
}

.read span{
    margin: auto;
    cursor: pointer;
}

.read span:hover{
    transform: scale(1.1);
}

.slick-dots li button{
    gap: 2rem;
}


.slick-dots li button:before{
    color: transparent;
    border: 3px solid gray;
    border-radius: 10px;
    width: 10px;
    height: 20px;
    margin-top: 3rem;
    position: absolute;
}

.slick-dots li.slick-active button:before{
    color: transparent;
    background-color: var(--buttonColor);
    border: none;
}

.slick-dots li button:before{
    color: transparent;
    background-color: var(--buttonColor);
    border: none;
}

.card-wrapper:hover .card-p{
    display: block;
}

.card-wrapper:hover .read{
    display: block;
}

@keyframes slide{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 100%;
    }
}

/* @media screen and (max-width: 680px) {
    .card-wrapper{
        max-width: 20rem;
        height: 20rem;
        margin-right: 15rem;
    }

    .card-image img{
        width: 3rem;
        height: 3rem;
        margin-right: 9rem;
    }

    .card-divider{
        margin-right: 9rem;
    }

    .card-heading h1{
        font-size: .8rem;
        margin-right: 1rem;
    }

    .card-p{
        font-size: .6rem;
        margin-right: 1rem;
    }

    .read{
        font-size: .6rem;
        margin-right: 1rem;
    }

    .slick-dots{
        margin-left: -6.6rem;
    }

    .slick-dots li button:before{
        color: transparent;
        border: 1px solid gray;
        border-radius: 10px;
        width: 5px;
        height: 10px;
        margin-top: 3rem;
        position: absolute;
    }
} */

