.whatwe{
    max-width: 100%;
    height: 250vh;
    position: relative;
    overflow: hidden;
}

.whatwe-container{
    display: flex;
    flex-direction: column;
}

.w-blur1{
    height: 50rem;
    width: 50rem;
    background-color: var(--deepPurple);
    position: absolute;
    border-radius: 100%;
    backdrop-filter: blur(15px);
    transform: translate(-75%, -50%); 
    filter: blur(240px);
    z-index: 1;
}

.w-blur2{
    position: absolute;
    height: 50rem;
    background-color: var(--themeColor);
    width: 50rem;
    border-radius: 50%;
    right: -10rem;
    backdrop-filter: blur(15px);
    transform: translate(25%, 50%); 
    filter: blur(800px);
    z-index: 1;
}

.whatwe-heading{
    margin: 10rem 17rem;
    justify-content: space-between;
    display: flex;
}

.we-heading h1{
    color: var(--white);
    text-transform: uppercase;
    font-size: 3rem;
    max-width: 80%;
}

.we-divider{
    border: 1px solid var(--themeColor);
    width: 7rem;
}

.we-p{
    color: var(--white);
    margin-top: 1rem;
    margin-left: 15rem;
    width: 50%;
    line-height: 2rem;
    font-size: 1.2rem;
}

.slider{
    margin: 0 17rem;
    z-index: 1;
}

.we-work{
    margin-top: 20rem;
}

.lottie{
    width: 100%;
    margin-top: 55rem;
    position: absolute;
    z-index: 1;
}