.video-carousel{
    min-width: 1400px;
    max-width: 100%;
    height: 800px;
    margin: 10px;
    overflow: hidden;
}

@media screen and (max-width: 680px){
    .video-carousel{
        margin: auto;
        min-width: 300px;
        height: 200px;
        margin-right: 16rem;
        margin-top: 1rem;
    }
}