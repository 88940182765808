.cms{
    margin: 40% 0;
    position: relative;
    max-width: 100%;
}

.cms-content{
    margin-top: -10rem;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

 .first-cms-content{
    position: sticky;
    top: 0px;
} 

.first-cms-content h3{
    letter-spacing: 1px;
    font-size: 1.5rem;
    text-transform: uppercase;
    color: var(--white);
    margin-bottom: 2rem;
}

.first-cms-content h1{
 
    font-size: 2.5rem;
    width: 100%;
}

.first-cms-content>:nth-child(4){
    color: var(--themeColor);
}

.first-cms-content a{
    font-size: 1.5rem;
    max-width: 100%;
    text-decoration: none;
    color: var(--purple);
    position: absolute;
    margin-top: 4rem;
    line-height: 1px;
}

.first-cms-content img{
    position: absolute;
    margin-left: 29rem;
}


.rocket{
    margin-top: 50rem;
    display: flex;
    max-width: 100%;
    z-index: 10000;
    position: absolute;
}

.rocket img{
    max-width: 100%;
}
.rocket{
    max-width: 100%;
    height: 17rem;
    width: 8rem;
    animation: fadeInFromTop .1s linear forwards, floating ease-in-out 6s infinite;
}

.planet{
    margin-top: 55rem;
    position: absolute;
    margin-left: -50rem;
}

.planet img{
    width: 9rem;
    max-width: 100%;
}

.bg-clouds{
    bottom: 0;
}

@keyframes fadeInFromTop{
    from{
        opacity: 0;
        transform: translateX(-20px);
    }
    to{
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes floating{
    0%{
        transform: translate(0%, 0%) rotate(0deg);
    }
    100%{
        transform: translate(0%, 10%) rotate(0deg);
    }
    50%{
        transform: translate(0%, 10%) rotate(0deg);
    }
    75%{
        transform: translate(0%, 10%) rotate(0deg);
    }
    100%{
        transform: translate(0%, 0%) rotate(0deg);
    }
}

@media screen and (max-width: 680px) {
    .cms{
        max-width: 100%;
        height: 60vh;
        overflow: hidden;
        margin-top: 10rem;  
    }

    .cms-content{
        margin: auto;
    }

    .first-cms-content h3{
        font-size: .7rem;
    }

    .first-cms-content h1{
        font-size: .7rem;
    }

    .read a{
        font-size: .6rem;
    }

    .read img{
        width: 1rem;
    }

    .rocket{
        margin-top: 30rem;
    }

    .rocket{
        max-width: 100%;
        height: 8rem;
        width: 8rem;
        animation: fadeInFromTop .1s linear forwards, floating ease-in-out 4s infinite;
    }

    .planet{
        margin: 0 auto;
        top: 17rem;
        left: 5rem;
    }

    .planet img{
        width: 4rem;
        max-width: 100%;
    }
}